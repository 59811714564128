import { AddIcon, Search2Icon } from '@chakra-ui/icons'
import { Box, Flex, Input, InputGroup, InputRightElement, Select, Spacer, Toast, useToast } from '@chakra-ui/react'
import React, { useContext, useState } from 'react'
import { useEffect } from 'react'
import { useOutletContext, useNavigate } from 'react-router-dom'
import { AuthContextState } from '../../../context/AuthContext'
import { useUrlQuery } from '../../../hooks/useUrlQuery'
import { halamanColumns } from '../../../lib/const/table-columns'
import { deletedToast } from '../../../lib/const/toast'
import { deletePages, getAllPages } from '../../../services/user.services'
import PageTitle from '../_components/PageTitle'
import TableData from '../_components/TableData'

function Halaman() {

    const [, setTopRightButton] = useOutletContext()
    const [auth, setAuth] = useContext(AuthContextState)
    const [pages, setPages] = useState([])
    const [metaTable, setMetaTable] = useState({})
    const [isLoading, setLoading] = useState(true)
    const { urlQuery, addQuery } = useUrlQuery()
    const navigate = useNavigate()
    const toast = useToast()

    const deleteHandler = (id) => {
        setLoading(true)
        deletePages(auth, id)
            .then(data => {
                toast(deletedToast)
                console.log(data)
                init()
            })
            .catch(e => {
                setLoading(false)
                console.log(e)
            })
    }

    const responseHandler = (data) => {
        const pages = data.data.data.map(item => {
            item.action = {
                delete: () => deleteHandler(item.id),
                id: item.id,
                slug: item.slug,
                type: item.type
            }

            return item
        })

        setPages(pages)
        console.log(data.data.data)
        setMetaTable({
            current_page: data.data.meta.current_page,
            per_page: data.data.meta.per_page,
            from: data.data.meta.from,
            to: data.data.meta.to,
            total: data.data.meta.total,
            next: data.data.links.next,
            prev: data.data.links.prev,
        })
        setLoading(false)
    }

    const errorHandler = e => {
        console.log(e)
        setLoading(false)
    }

    const nextPageHandler = () => {
        setLoading(true)
        const current_page = parseInt(urlQuery.get('p')) || 1
        addQuery('p', current_page + 1)
    }

    const prevPageHandler = () => {
        setLoading(true)
        const current_page = parseInt(urlQuery.get('p')) || 1
        addQuery('p', current_page - 1)
    }



    const tableProps = {
        columns: halamanColumns,
        data: pages,
        isLoading,
        metaTable: metaTable,
        prevPageHandler: prevPageHandler,
        nextPageHandler: nextPageHandler
    }

    const init = () => {

        getAllPages(auth, urlQuery.get('p') || 1)
            .then(responseHandler)
            .catch(errorHandler)
    }

    useEffect(() => {
        init()

        setTopRightButton({
            text: 'Tambah Halaman',
            icon: <AddIcon />,
            onClick: () => navigate('/halaman/add')
        })
    }, [urlQuery])

    return (
        <Box bg='gray.50' p={4} mt={8} color='gray.700'>
            <PageTitle mb={4} title='Halaman' />
            <Flex>
                <Select placeholder='Tanggal' bg='white' width='20%' mr={2} />
                <Select placeholder='Kategori' bg='white' width='20%' />
                <Spacer />
                <InputGroup width='30%'>
                    <Input placeholder='Ketik untuk mencari' bg='white' />
                    <InputRightElement color='gray.700' >
                        <Search2Icon />
                    </InputRightElement>
                </InputGroup>
            </Flex>
            <TableData {...tableProps} />
        </Box>
    )
}

export default Halaman