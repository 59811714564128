import { HamburgerIcon } from '@chakra-ui/icons'
import { Box, Button, Flex, Grid, GridItem, IconButton, Spacer, useDisclosure } from '@chakra-ui/react'
import React, { useContext, useEffect } from 'react'
import { useRef } from 'react'
import { useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { AuthContextState } from '../../context/AuthContext'
import { getBreadcrumbLink } from '../../lib/helper/getBreadcrumbLink'
import { getLoginStatus } from '../../services/user.services'
import CustomBreadcrumb from './_components/CustomBreadcrumb'
import DrawerDashboard from './_components/DrawerDashboard'
import Navbar from './_components/Navbar'
import Sidebar from './_components/Sidebar/Sidebar'


function DashboardRoot() {
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const breadcrumb = getBreadcrumbLink(pathname)
    const [auth, setAuth] = useContext(AuthContextState)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnDrawerRef = useRef()
    const [topRightButton, setTopRightButton] = useState(null)

    useEffect(() => {
        if (auth === null) navigate('/auth')
        if (auth !== null && pathname === '/') navigate('/dashboard')
    }, [])

    return (
        <Grid
            templateAreas={{
                base: `"navbar"
                    "main"`,
                lg: `"sidebar navbar"
                    "sidebar main"`,
            }}
            gridTemplateRows={'86px 1fr'}
            gridTemplateColumns={{ base: '1fr', lg: '240px 1fr', }}
        >
            <GridItem display={{ base: 'none', lg: 'inline' }} bg='gray.50' h='100%' area={'sidebar'}>
                <Sidebar />
            </GridItem>
            <GridItem bg="gray.800" area={'navbar'}>
                <Navbar drawerButton={<IconButton ref={btnDrawerRef} onClick={onOpen} display={{ base: 'inline', lg: 'none' }} size='lg' variant='ghost' colorScheme='whiteAlpha' icon={<HamburgerIcon />} />} />
                <DrawerDashboard isOpen={isOpen} onClose={onClose} ref={btnDrawerRef} ><Sidebar /></DrawerDashboard>
            </GridItem>
            <GridItem p={8} area={'main'}>
                <Flex>
                    <CustomBreadcrumb listItem={breadcrumb} />
                    <Spacer />
                    {
                        topRightButton !== null ?
                            <Button colorScheme='green' bg='sidebar-active' onClick={topRightButton.onClick} color='white' fontWeight={600} rightIcon={topRightButton.icon}>{topRightButton.text}</Button>
                            : ''
                    }
                </Flex>
                <Box mt={8}>
                    <Outlet context={[topRightButton, setTopRightButton]} />
                </Box>
            </GridItem>
        </Grid >


    )
}

export default DashboardRoot