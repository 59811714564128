
import { Box, Button, Flex, Image, Input, Spacer, Text, Textarea, useToast } from '@chakra-ui/react'
import { Fragment } from 'react'
import { useEffect } from 'react'
import { useContext } from 'react'
import { useState } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'
import { AuthContextState } from 'src/context/AuthContext'
import { errorAxiosToast, updatedToast } from 'src/lib/const/toast'
import PageTitle from 'src/pages/Dashboard/_components/PageTitle'
import {  getPMB, updatePMB } from 'src/services/user.services'
import TextEditor from '../../_components/TextEditor'
import { BASE_API_URL } from 'src/services/_base.api.url'
import { NO_PICTURE } from 'src/lib/const/texteditor-config'

function PMB() {
    const [, setTopRightButton] = useOutletContext()

    const [description, setDescription] = useState('')
    const [descriptionError, setDescriptionError] = useState('')
    const [loading, setLoading] = useState(true)
    const [title, setTitle] = useState('')
    const [titleError, setTitleError] = useState('')
    const toast = useToast()
    const param = useParams()
    const [auth,] = useContext(AuthContextState)

    const init = () => {
        setLoading(true)
        getPMB(auth).then(data => {
            console.log(data)
            setDescription(data.data.data.description)
            setTitle(data.data.data.title)
            // setImage(data.data.data.image)
            setLoading(false)
        }
        ).catch(e => {
            console.log(e)
            setLoading(false)
        })
    }

    const onSubmitSectionHandler = () => {
        setLoading(true)

        if (description === '') {
            setDescriptionError('Deskripsi tidak boleh kosong')
            setLoading(false)
            return
        }

        if (title === '') {
            setTitleError('Judul tidak boleh kosong')
            setLoading(false)
            return
        }

        const data = {
            description: description,
            title: title,
        }

        updatePMB(auth, data).then(data => {
            console.log(data)
            toast(updatedToast)
            init()
            setLoading(false)
        }).catch(e => {
            console.log(e)
            toast(errorAxiosToast(e))
            setLoading(false)
        })
    }


    useEffect(() => {
        init()
        setTopRightButton(null)
    }, [])

    return (
        <Fragment>
            <Box bg='gray.100' p={4} fontWeight={700} roundedTop={4}>
                <PageTitle title='Opening' showIcon={false} mt={0} mb={0} />
            </Box>
            <Box bg='gray.50' color='gray.700' p={4}>
                <Box mt={8} width='99.5%' overflow='auto' >
                    <Text fontWeight={500}>Judul : {title}</Text>
                    {
                        // <Input isInvalid={titleError} disabled={loading} placeholder='Nama' bg='white' my={4} value={title} onChange={(e) => setTitle(e.target.value)} />
                        // {titleError ? <Text fontSize={14} color='red.500'>{titleError}</Text> : ''}
                    }
                </Box>
                <Box mt={8} width='99.5%' overflow='auto' >
                    <Text mb={4} fontWeight={500}>Deskripsi</Text>
                    <Textarea value={description} disabled={loading} maxLength={3000} placeholder='Deskripsi' bg='white' my={4} onChange={(e) => setDescription(e.target.value)} />
                    {descriptionError ? <Text fontSize={14} color='red.500'>{descriptionError}</Text> : ''}
                </Box>
            </Box>

            <Flex>
                <Spacer />
                <Button isLoading={loading} mt={4} mb={16} colorScheme='teal' bg='teal.500' onClick={onSubmitSectionHandler}>Simpan</Button>
            </Flex>
        </Fragment>

    )
}

export default PMB