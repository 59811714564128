export const textCutter = (text, length = 30) => {
    if (text == null) {
        return "";
    }
    if (text.length <= length) {
        return text;
    }

    // text = text.substring(0, length);
    // let last = text.lastIndexOf(" ");
    // text = text.substring(0, last);
    text = text.substring(0, length);
    return text + "...";
}