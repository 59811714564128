import { Checkbox } from "@chakra-ui/react";
import { useEffect } from "react";
import { useRef } from "react";
import { forwardRef } from "react";

export const TableCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
    const defaultRef = useRef()
    const resolvedRef = ref || defaultRef

    useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])
    rest.isChecked = rest.checked
    return <Checkbox ref={resolvedRef} {...rest} />
})