import { AddIcon, Search2Icon } from '@chakra-ui/icons'
import { Box, Flex, Input, InputGroup, InputRightElement, Select, Spacer, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { AuthContextState } from '../../../../context/AuthContext'
import { useUrlQuery } from '../../../../hooks/useUrlQuery'
import { publikasiColumns } from '../../../../lib/const/table-columns'
import { deletedToast, pinContentToast, unpinContentToast } from '../../../../lib/const/toast'
import { capFirstLetter } from '../../../../lib/helper/capitalizeFirstLetter'
import { deletePublikasi, getAllIreUpdate, getAllPublikasi, setPinPublikasi } from '../../../../services/user.services'
import PageTitle from '../../_components/PageTitle'
import PinWarning from '../../_components/PinWarning'
import TableData from '../../_components/TableData'

function Publikasi({ customPage = false }) {
    const [auth, setAuth] = useContext(AuthContextState)
    const param = useParams()
    const [publikasi, setPublikasi] = useState([])
    const [metaTable, setMetaTable] = useState({})
    const [isLoading, setLoading] = useState(true)
    const { urlQuery, addQuery } = useUrlQuery()
    const navigate = useNavigate()
    const toast = useToast()
    const [, setTopRightButton] = useOutletContext()
    const slugPage = customPage ? param.slugPage : ''
    const pageTitle = customPage ? capFirstLetter(slugPage.split('-').join(' ')) : 'Publikasi'


    const pinDisclosure = useDisclosure()
    const pinCancelRef = useRef()

    const deleteHandler = (slug) => {
        setLoading(true)

        let payload

        if (customPage) {
            payload = { slug, slugPage }
        } else {
            payload = { slug }

        }

        deletePublikasi(auth, payload)
            .then(data => {
                toast(deletedToast)
                console.log(data)
                init()
            })
            .catch(e => {
                setLoading(false)
                console.log(e)
            })
    }

    const changePinnedHandler = (id, slug, list) => {
        setLoading(true)
        let payload = {
            id: id,
            slug: slug,
            pinned: !list.filter(item => item.id === id)[0]['pinned_at']
        }

        if (customPage) {
            payload = { ...payload, slugPage }
        }

        // console.log(payload)

        setPinPublikasi(auth, payload)
            .then(res => {
                console.log(res)
                if (payload.pinned) {
                    toast(pinContentToast)
                } else {
                    toast(unpinContentToast)
                }
                init()
            })
            .catch(e => {
                console.log(e)
                if (e.response.status === 400) pinDisclosure.onOpen()
                setLoading(false)
            })
    }

    const editHandler = (slug) => navigate(customPage ? `/halaman/${slugPage}/publication/${slug}/edit` : `/beranda/publikasi/${slug}/edit`)

    const responseHandler = (data) => {
        const result = data.data.data.map(item => {
            item.action = {
                delete: () => deleteHandler(item.slug),
                edit: () => editHandler(item.slug)

            }
            item.pinned_action = {
                onClick: () => changePinnedHandler(item.id, item.slug, data.data.data),
                pinned: item.pinned_at
            }

            item.author_name = item.author_name || 'Admin'
            return item
        })
        setPublikasi(result)
        setMetaTable({
            current_page: data.data.meta.current_page,
            per_page: data.data.meta.per_page,
            from: data.data.meta.from,
            to: data.data.meta.to,
            total: data.data.meta.total,
            next: data.data.links.next,
            prev: data.data.links.prev,
        })
        setLoading(false)
    }

    const errorHandler = e => {
        console.log(e)
        setLoading(false)
    }

    const nextPageHandler = () => {
        setLoading(true)
        const current_page = parseInt(urlQuery.get('p')) || 1
        addQuery('p', current_page + 1)
    }

    const prevPageHandler = () => {
        setLoading(true)
        const current_page = parseInt(urlQuery.get('p')) || 1
        addQuery('p', current_page - 1)
    }

    const init = () => {

        let payload = {
            page: urlQuery.get('p') || 1
        }
        if (customPage) {
            payload = {
                slugPage,
                ...payload
            }
        }
        getAllPublikasi(auth, payload)
            .then(responseHandler)
            .catch(errorHandler)
    }

    useEffect(() => {
        init()
        setTopRightButton({
            text: customPage ? 'Tambah Item' : 'Tambah Publikasi',
            icon: <AddIcon />,
            onClick: () => navigate(customPage ? `/halaman/${slugPage}/publication/add` : '/beranda/publikasi/add')
        })
    }, [urlQuery])

    const tableProps = {
        columns: publikasiColumns,
        data: publikasi,
        isLoading,
        metaTable,
        prevPageHandler,
        nextPageHandler
    }


    return (
        <Box bg='gray.50' p={4} mt={8} color='gray.700'>
            <PinWarning cancelRef={pinCancelRef} {...pinDisclosure} />
            <PageTitle mb={4} title={pageTitle} />
            <Flex>
                <Select placeholder='Tanggal' bg='white' width='20%' mr={2} />
                <Select placeholder='Kategori' bg='white' width='20%' />
                <Spacer />
                <InputGroup width='30%'>
                    <Input placeholder='Ketik untuk mencari' bg='white' />
                    <InputRightElement color='gray.700' >
                        <Search2Icon />
                    </InputRightElement>
                </InputGroup>
            </Flex>
            <TableData {...tableProps} />
        </Box>
    )
}

export default Publikasi