import { Flex, Spinner, Td, Tr } from '@chakra-ui/react'
import React from 'react'

function TableLoading() {
    return (
        <Tr>
            <Td colSpan={7}>
                <Flex justifyContent='center' alignItems='center' w='100%' h='200px'>
                    <Spinner
                        thickness='5px'
                        color='teal.500'
                        size='lg'
                    />
                </Flex>
            </Td>
        </Tr>
    )
}

export default TableLoading