export const unauthenticateToast = {
    title: 'Sesi Login Berakhir',
    description: 'Silahkan login terlebih dahulu',
    status: 'error',
    position: 'top'
}

export const createdToast = {
    title: 'Sukses',
    description: 'Data berhasil dibuat',
    status: 'success',
    position: 'top'
}

export const updatedToast = {
    title: 'Sukses',
    description: 'Perubahan berhasil disimpan',
    status: 'success',
    position: 'top'
}

export const deletedToast = {
    title: 'Sukses',
    description: 'Item berhasil dihapus',
    status: 'success',
    position: 'top'
}

export const titleSavedToast = {
    title: 'Sukses',
    description: 'Judul berhasil disimpan',
    status: 'success',
    position: 'top'
}

export const thumbnailChangedToast = {
    title: 'Sukses',
    description: 'Gambar sampul berhasil diubah',
    status: 'success',
    position: 'top'
}

export const docChangedToast = {
    title: 'Sukses',
    description: 'Dokument berhasil diubah',
    status: 'success',
    position: 'top'
}

export const imageReadyToast = {
    title: 'Sukses',
    description: 'Gambar siap diunggah',
    status: 'success',
    position: 'top'
}

export const approvedToast = {
    title: 'Sukses',
    description: 'Berhasil diubah',
    status: 'success',
    position: 'top'
}

export const imageEmptyToast = {
    title: 'Gagal',
    description: 'Silahkan pilih gambar terlebih dahulu',
    status: 'error',
    position: 'top'
}

export const imageFormatWrong = {
    title: 'Gagal',
    description: 'Format file tidak didukung',
    status: 'error',
    position: 'top'
}

export const pinContentToast = {
    title: 'Sukses',
    description: 'Konten berhasil di-pin',
    status: 'success',
    position: 'top'
}

export const manageHomeVideoToast = {
    title: 'Sukses',
    description: 'Konten berhasil setting ke Home',
    status: 'success',
    position: 'top'
}

export const unpinContentToast = {
    title: 'Sukses',
    description: 'Konten berhasil di-unpin',
    status: 'success',
    position: 'top'
}

export const orderTeamToastSuccess = {
    title: 'Sukses',
    description: 'Urutan berhasil diupdate',
    status: 'success',
    position: 'top'
}

export const orderTeamToastFailed = {
    title: 'Gagal',
    description: 'Urutan gagal diupdate',
    status: 'error',
    position: 'top'
}

// Error toast for axios
export const errorAxiosToast = (e) => {
    const { status } = e.response

    if (status === 401) {
        return unauthenticateToast
    } else if (status === 500) {
        return error500Toast
    } else if (status === 404) {
        return error404Toast
    } else if (status === 400) {
        return error400Toast
    } else if (status === 422) {
        return error422Toast
    }

    return error500Toast
}

export const error500Toast = {
    title: 'Gagal',
    description: 'Terjadi kesalahan',
    status: 'error',
    position: 'top'
}

export const error404Toast = {
    title: 'Gagal',
    description: 'Halaman tidak ditemukan',
    status: 'error',
    position: 'top'
}

export const error400Toast = {
    title: 'Gagal',
    description: 'Permintaan tidak dapat diproses',
    status: 'error',
    position: 'top'
}

export const error422Toast = {
    title: 'Gagal',
    description: 'Data tidak valid',
    status: 'error',
    position: 'top'
}