import React, { createContext, useState } from 'react'
import { getLocalSession } from '../services/session.service'

export const AuthContextState = createContext()


function AuthContext({ children }) {

    const authState = useState(getLocalSession())

    return (
        <AuthContextState.Provider value={authState}>
            {children}
        </AuthContextState.Provider>
    )
}

export default AuthContext