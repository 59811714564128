import { Search2Icon } from '@chakra-ui/icons'
import { Box, Flex, Input, InputGroup, InputRightElement, Select, Spacer, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useContext } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { AuthContextState } from '../../../context/AuthContext'
import { useUrlQuery } from '../../../hooks/useUrlQuery'
import { komentarColumns } from '../../../lib/const/table-columns'
import { approvedToast, unauthenticateToast } from '../../../lib/const/toast'
import { getAllComments, setCommentApproval } from '../../../services/user.services'
import PageTitle from '../_components/PageTitle'
import TableData from '../_components/TableData'

function Komentar() {
    const [, setTopRightButton] = useOutletContext()
    const [auth,] = useContext(AuthContextState)
    const [loading, setLoading] = useState(true)
    const [comment, setComment] = useState([])
    const [metaTable, setMetaTable] = useState({})
    const { addQuery, urlQuery } = useUrlQuery()

    const toast = useToast()
    const navigate = useNavigate()

    const approveHandler = (id, value) => {
        setLoading(true)
        setCommentApproval(auth, { id, value })
            .then(data => {
                console.log(data)
                init()
                toast(approvedToast)
                // setLoading(false)
            })
            .catch(e => {
                console.log(e)
                setLoading(false)
            })
    }

    const responseHandler = (data) => {
        const comment = data.data.data.map(item => {
            item.action = {
                approve: () => approveHandler(item.id, !item.approved),
                value: item.approved
            }
            return item
        })
        setComment(comment)
        console.log(comment)

        setMetaTable({
            current_page: data.data.meta.current_page,
            per_page: data.data.meta.per_page,
            from: data.data.meta.from,
            to: data.data.meta.to,
            total: data.data.meta.total,
            next: data.data.links.next,
            prev: data.data.links.prev,
        })
        setLoading(false)
    }

    const errorHandler = e => {
        console.log(e)
        const { status } = e.response

        if (status === 401) {
            toast(unauthenticateToast)
            navigate('/auth/logout')
        }

        setLoading(false)
    }

    const nextPageHandler = () => {
        setLoading(true)
        const current_page = parseInt(urlQuery.get('p')) || 1
        addQuery('p', current_page + 1)
    }

    const prevPageHandler = () => {
        setLoading(true)
        const current_page = parseInt(urlQuery.get('p')) || 1
        addQuery('p', current_page - 1)
    }

    const tableProps = {
        columns: komentarColumns,
        data: comment,
        isLoading: loading,
        metaTable: metaTable,
        prevPageHandler: prevPageHandler,
        nextPageHandler: nextPageHandler
    }

    const init = () => {
        getAllComments(auth, urlQuery.get('p') || 1)
            .then(responseHandler)
            .catch(errorHandler)
    }

    useEffect(() => {
        init()
        setTopRightButton(null)
    }, [urlQuery])
    return (
        <Box bg='gray.50' p={4} mt={8} color='gray.700'>
            <Flex>
                <PageTitle mb={4} title='Komentar' />
                <Spacer />
                <InputGroup width='30%'>
                    <Input placeholder='Ketik untuk mencari' bg='white' />
                    <InputRightElement color='gray.700' >
                        <Search2Icon />
                    </InputRightElement>
                </InputGroup>
            </Flex>
            <TableData {...tableProps} />
        </Box>
    )
}

export default Komentar