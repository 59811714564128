import { Flex } from '@chakra-ui/react'
import React from 'react'
import YouTube from 'react-youtube'

function YoutubeVideoPlayer({ videoId }) {
    return (
        <Flex justifyContent='center' >

            <YouTube videoId={videoId} onReady={(e) => e.target.pauseVideo()} />
        </Flex >
    )
}

export default YoutubeVideoPlayer

//yHly0jqRprs