export const LIST_SIDEBAR = [
    {
        name: 'Dashboard',
        link: '/dashboard',
        child: [],
    },
    {
        name: 'Beranda',
        link: '/beranda',
        child: [
            {
                name: 'Banner',
                link: '/beranda/banner',
                child: [],
            },
            {
                name: 'Tentang',
                link: '/beranda/tentang-kami',
                child: [],
            },
            {
                name: 'Organisasi',
                link: '/beranda/organisasi',
                child: [],
            },
            {
                name: 'PMB',
                link: '/beranda/pmb',
                child: [],
            },
            {
                name: 'Jurusan',
                link: '/beranda/jurusan',
                child: [],
            },
            {
                name: 'Artikel',
                link: '/beranda/artikel',
                child: [],
            },
            {
                name: 'Galeri Foto',
                link: '/beranda/foto',
                child: [],
            },
            {
                name: 'Galeri Video',
                link: '/beranda/video',
                child: [],
            },
        ],
    },
    {
        name: 'Halaman',
        link: '/halaman',
        child: [
            {
                name: 'Semua Halaman',
                link: '/halaman/',
                child: [],
            },
            {
                name: 'Tambah Halaman',
                link: '/halaman/add',
                child: [],
            }
        ],
    },
    {
        name: 'Komentar',
        link: '/komentar',
        child: [],
    },
    // {
    //     name: 'Download',
    //     link: '/download',
    //     child: [],
    // },


]