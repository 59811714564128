import { AddIcon, Search2Icon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Spacer,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { AuthContextState } from "../../../../context/AuthContext";
import { useUrlQuery } from "../../../../hooks/useUrlQuery";
import { videoColumns } from "../../../../lib/const/table-columns";
import {
  deletedToast,
  manageHomeVideoToast,
  pinContentToast,
  unauthenticateToast,
  unpinContentToast,
} from "../../../../lib/const/toast";
import { capFirstLetter } from "../../../../lib/helper/capitalizeFirstLetter";
import {
  deleteVideo,
  getAllVideo,
  setPinVideo,
  setVideoToHome,
} from "../../../../services/user.services";
import PageTitle from "../../_components/PageTitle";
import PinWarning from "../../_components/PinWarning";
import TableData from "../../_components/TableData";

function Video({ customPage = false }) {
  const [, setTopRightButton] = useOutletContext();
  const param = useParams();
  const [auth, setAuth] = useContext(AuthContextState);
  const [videos, setVideos] = useState([]);
  const [metaTable, setMetaTable] = useState({});
  const [isLoading, setLoading] = useState(true);
  const { addQuery, urlQuery } = useUrlQuery();
  const toast = useToast();
  const navigate = useNavigate();
  const slugPage = customPage ? param.slugPage : "galeri-video";
  const pageTitle = customPage
    ? capFirstLetter(slugPage.split("-").join(" "))
    : "Galeri Video";

  const pinDisclosure = useDisclosure();
  const pinCancelRef = useRef();

  const deleteHandler = (slug) => {
    setLoading(true);

    let payload = { slug };

    if (customPage) {
      payload = { ...payload, slugPage };
    }

    deleteVideo(auth, payload)
      .then((data) => {
        navigate(customPage ? `'/halaman/${slugPage}/video` : "/beranda/video");
        toast(deletedToast);
        console.log(data);
        init();
      })
      .catch((e) => {
        setLoading(false);
        const { status, data } = e.response;
        if (status === 401) {
          toast(unauthenticateToast);
          navigate("/auth/logout");
        }
        if (status === 422) {
          console.log(data);
          toast({
            title: "Gagal",
            description: data.message,
            status: "error",
            position: "top",
          });
        }
        console.log(e);
      });
  };

  const changePinnedHandler = (id, list) => {
    setLoading(true);
    let payload = {
      id: id,
      pinned: !list.filter((item) => item.id === id)[0]["pinned_at"],
    };

    if (customPage) {
      payload = { ...payload, slugPage };
    }

    // console.log(payload)

    setPinVideo(auth, payload)
      .then((res) => {
        console.log(res);
        if (payload.pinned) {
          toast(pinContentToast);
        } else {
          toast(unpinContentToast);
        }
        init();
      })
      .catch((e) => {
        console.log(e);
        if (e.response.status === 400) pinDisclosure.onOpen();
        setLoading(false);
      });
  };

  const editHandler = (id) =>
    navigate(
      customPage
        ? `/halaman/${slugPage}/video/${id}/edit`
        : `/beranda/video/${id}/edit`
    );

  const manageHomeVideoHandler = (id) => {
    setLoading(true);

    let payload = { id };

    if (customPage) {
      payload = { ...payload, slugPage };
    }

    setVideoToHome(auth, payload)
      .then((data) => {
        toast(manageHomeVideoToast);
        console.log(data);
        init();
      })
      .catch((e) => {
        setLoading(false);
        const { status, data } = e.response;
        if (status === 401) {
          toast(unauthenticateToast);
          navigate("/auth/logout");
        }
        if (status === 422) {
          console.log(data);
          toast({
            title: "Gagal",
            description: data.message,
            status: "error",
            position: "top",
          });
        }
        console.log(e);
      });
  };

  const responseHandler = (data) => {
    const videos = data.data.data.map((item) => {
      item.action = {
        delete: () => deleteHandler(item.slug),
        edit: () => editHandler(item.id),
        manageHomeVideo: () => manageHomeVideoHandler(item.id),
      };
      item.pinned_action = {
        onClick: () => changePinnedHandler(item.id, data.data.data),
        pinned: item.pinned_at,
      };
      return item;
    });
    setVideos(videos);
    console.log(data.data.data);
    setMetaTable({
      current_page: data.data.meta.current_page,
      per_page: data.data.meta.per_page,
      from: data.data.meta.from,
      to: data.data.meta.to,
      total: data.data.meta.total,
      next: data.data.links.next,
      prev: data.data.links.prev,
    });
    setLoading(false);
  };

  const errorHandler = (e) => {
    console.log(e);
    const { status, data } = e.response;
    if (status === 401) {
      toast(unauthenticateToast);
      navigate("/auth/logout");
    }
    if (status === 422) {
      console.log(data);
      toast({
        title: "Gagal",
        description: data.message,
        status: "error",
        position: "top",
      });
    }
    setLoading(false);
  };

  const nextPageHandler = () => {
    setLoading(true);
    const current_page = parseInt(urlQuery.get("p")) || 1;
    addQuery("p", current_page + 1);
  };

  const prevPageHandler = () => {
    setLoading(true);
    const current_page = parseInt(urlQuery.get("p")) || 1;
    addQuery("p", current_page - 1);
  };

  const tableProps = {
    columns: videoColumns,
    data: videos,
    isLoading: isLoading,
    metaTable: metaTable,
    prevPageHandler: prevPageHandler,
    nextPageHandler: nextPageHandler,
  };

  const init = () => {
    let payload = {
      page: urlQuery.get("p") || 1,
    };
    if (customPage) {
      payload = { slugPage, ...payload };
    }
    getAllVideo(auth, payload).then(responseHandler).catch(errorHandler);
  };

  useEffect(() => {
    init();
    setTopRightButton({
      text: "Tambah Video",
      icon: <AddIcon />,
      onClick: () =>
        navigate(
          customPage ? `/halaman/${slugPage}/video/add` : "/beranda/video/add"
        ),
    });
  }, [urlQuery]);
  return (
    <Box bg="gray.50" p={4} mt={8} color="gray.700">
      <PinWarning cancelRef={pinCancelRef} {...pinDisclosure} />
      <PageTitle mb={4} title={pageTitle} />
      <Flex>
        <Select placeholder="Date" bg="white" width="20%" mr={2} />
        <Select placeholder="Category" bg="white" width="20%" />
        <Spacer />
        <InputGroup width="30%">
          <Input placeholder="Ketik untuk mencari" bg="white" />
          <InputRightElement color="gray.700">
            <Search2Icon />
          </InputRightElement>
        </InputGroup>
      </Flex>
      <TableData {...tableProps} />
    </Box>
  );
}

export default Video;
