import noImage from "../../../src/assets/images/no-image.png"
import { Avatar, Box, Button, Checkbox, Flex, Icon, Image, Link, Menu, MenuButton, MenuItem, MenuList, Text, Tooltip, useDisclosure, VStack } from "@chakra-ui/react";
import { format } from "date-fns";
import StatusBadge from "../../pages/Dashboard/_components/StatusBadge";
import ActionListTableItem from "../../pages/Dashboard/_components/ActionListTableItem";
import { FaPlay } from 'react-icons/fa'
import { Fragment, useRef } from "react";
import CustomModal from "../../pages/Dashboard/_components/CustomModal";
import YoutubeVideoPlayer from "../../pages/Dashboard/_components/YoutubeVideoPlayer";
import { getVideoID } from "../helper/getVideoId";
import { useNavigate } from "react-router-dom";
import { textCutter } from "../helper/textCutter";
import { capFirstLetter } from "../helper/capitalizeFirstLetter";
import { BsEye, BsPersonCheck } from "react-icons/bs";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { HiDotsVertical } from "react-icons/hi";
import PinnedCheckBox from "../../pages/Dashboard/_components/PinnedCheckBox";

const styledHeader = (value) => {
    return () => <Text color='gray.800' fontSize={13}>{value}</Text>
}

// DASHBOARD
export const getDashboardColumns = (type) => {
    let columns = []

    if (type === 'download') {
        columns = [
            {
                Header: styledHeader('Nama'),
                accessor: 'name',
            },
            {
                Header: styledHeader('Email'),
                accessor: 'email',
            },
            {
                Header: styledHeader('Pekerjaan'),
                accessor: 'work',
            },
        ]
    } else if (type === 'distributor' || type === 'produk') {
        columns = [
            {
                Header: styledHeader('Judul'),
                accessor: 'title',
                Cell: ({ value }) => (
                    <Tooltip label={value}>
                        {textCutter(value, 40)}
                    </Tooltip>
                )
            },
            {
                Header: styledHeader('Penulis'),
                accessor: 'author_name',
            },
            {
                Header: styledHeader('Tanggal Publish'),
                accessor: 'published_at',
                Cell: ({ value }) => format(new Date(value), 'dd/MM/yyyy')
            },
            {
                Header: styledHeader('Status'),
                accessor: 'visibility',
                Cell: ({ value }) => {
                    return <StatusBadge status={value === "public" ? 'success' : ''} message={value} />
                }
            },
        ]
    }


    return columns
}

// POST
export const ireUpdateColumns = [
    {
        Header: styledHeader('Judul'),
        accessor: 'title',
        Cell: ({ value }) => (
            <Tooltip label={value}>
                {textCutter(value, 40)}
            </Tooltip>
        )
    },
    {
        Header: styledHeader('Penulis'),
        accessor: 'author_name',
    },
    {
        Header: styledHeader('Tanggal Publish'),
        accessor: 'published_at',
        Cell: ({ value }) => format(new Date(value), 'dd/MM/yyyy')
    },
    {
        Header: styledHeader('Status'),
        accessor: 'visibility',
        Cell: ({ value }) => {
            return <StatusBadge status={value === "public" ? 'success' : ''} message={value} />
        }
    },
    {
        Header: styledHeader('Pin'),
        accessor: 'pinned_action',
        Cell: ({ value }) => <PinnedCheckBox onClick={value.onClick} isChecked={value.pinned} />
    },
    {
        Header: styledHeader(" "),
        accessor: 'action',
        Cell: ({ value }) => {
            return <ActionListTableItem onEdit={value.onEdit} onDelete={value.delete} />
        }

    }
]

export const publikasiColumns = [
    {
        Header: styledHeader('Judul'),
        accessor: 'title',
        Cell: ({ value }) => (
            <Tooltip label={value}>
                {textCutter(value, 30)}
            </Tooltip>
        )
    },
    {
        Header: styledHeader('Penulis'),
        accessor: 'author_name'
    },
    {
        Header: styledHeader('Tanggal Publish'),
        accessor: 'published_at',
        Cell: ({ value }) => format(new Date(value), 'dd/MM/yyyy')
    },
    {
        Header: styledHeader('Status'),
        accessor: 'visibility',
        Cell: ({ value }) => {
            return <StatusBadge status={value === "public" ? 'success' : ''} message={value} />
        }
    },
    {
        Header: styledHeader('Pin'),
        accessor: 'pinned_action',
        Cell: ({ value }) => <PinnedCheckBox onClick={value.onClick} isChecked={value.pinned} />
    },
    {
        Header: styledHeader(" "),
        accessor: 'action',
        Cell: ({ value }) => {
            return <ActionListTableItem onEdit={value.edit} onDelete={value.delete} />
        }

    }
]

// DASHBOARD
// export const dashboardColumns = [
//     {
//         Header: styledHeader('Judul'),
//         accessor: 'title'
//     },
//     {
//         Header: styledHeader('Penulis'),
//         accessor: 'author'
//     },
//     {
//         Header: styledHeader('Tag'),
//         accessor: 'tags'
//     },
//     {
//         Header: styledHeader('Tanggal Publish'),
//         accessor: 'created_at',
//         Cell: ({ value }) => format(new Date(value), 'dd/MM/yyyy')
//     },
//     {
//         Header: styledHeader('Status'),
//         accessor: 'status',
//         Cell: ({ value }) => <StatusBadge status={'success'} message={value} />
//     },

// ]

// HOME
export const bannerColumns = [
    {
        Header: styledHeader('Gambar'),
        accessor: 'url_thumbnail',
        Cell: ({ value }) => {
            if (!value) {
                return <Image h='100px' w='100px' fit='cover' src={noImage} alt='banner' onError={e => e.target.src = noImage} />
            }
            return <Image h='100px' w='100px' fit='cover' src={value} onError={e => e.target.src = noImage} alt='banner' />
        }

    },
    {
        Header: styledHeader('Diunggah oleh'),
        accessor: 'first_name',
        Cell: ({ value }) => capFirstLetter(value || 'Admin')
    },
    {
        Header: styledHeader('Tipe'),
        accessor: 'type',
        Cell: ({ value }) => capFirstLetter(value)
    },
    {
        Header: styledHeader('Tanggal'),
        accessor: 'created_at',
        Cell: ({ value }) => format(new Date(value), 'dd/MM/yyyy')
    },
    {
        Header: styledHeader(' '),
        accessor: 'action',
        Cell: ({ value }) => {
            return <ActionListTableItem disabled={value.isDisabled} onEdit={value.edit} onDelete={value.delete} />
            // return '...'
        }
    },
]

export const tentangColumns = [
    {
        Header: styledHeader('Gambar'),
        accessor: 'image',
        Cell: ({ value }) => <Image h='60px' w='100px' fit='cover' src={value} alt='banner' />

    },
    {
        Header: styledHeader('Dibuat Oleh'),
        accessor: 'role'
    },
    {
        Header: styledHeader('Tanggal'),
        accessor: 'date',
        Cell: ({ value }) => format(new Date(value), 'dd/MM/yyyy')
    },
    {
        Header: styledHeader(" "),
        accessor: 'id',
        Cell: ({ value }) => {
            const navigate = useNavigate()
            return <ActionListTableItem id={value} onEdit={() => navigate('/beranda/ire/tentang/edit')} />
        }

    }
]

export const bookColumns = [
    {
        Header: styledHeader('Gambar'),
        accessor: 'image',
        Cell: ({ value }) => <Image h='100px' w='60px' fit='cover' src={value} alt='banner' />

    },
    {
        Header: styledHeader('Judul'),
        accessor: 'title'
    },
    {
        Header: styledHeader('Diunggah Oleh'),
        accessor: 'role'
    },
    {
        Header: styledHeader('Tanggal'),
        accessor: 'date',
        Cell: ({ value }) => format(new Date(value), 'dd/MM/yyyy')
    },
    {
        Header: styledHeader('Pin'),
        accessor: 'pin',
        Cell: ({ value }) => <Checkbox isChecked={true} />
    }
]

export const videoColumns = [
    {
        Header: styledHeader('Video'),
        accessor: 'url',
        Cell: ({ value }) => {
            const disclosure = useDisclosure()
            const ref = useRef(null)
            const videoId = getVideoID(value)

            return (
                <Fragment>
                    <CustomModal disclosure={disclosure} ref={ref} size='4xl' body={<YoutubeVideoPlayer videoId={videoId} />} />
                    <Button rightIcon={<Icon size='sm' as={FaPlay} />} size='sm' colorScheme='teal' onClick={disclosure.onOpen}>Preview</Button>
                </Fragment>
            )
        }

    },
    {
        Header: styledHeader('Judul'),
        accessor: 'title',
        Cell: ({ value }) => (
            <Tooltip label={value}>
                {textCutter(value, 30)}
            </Tooltip>
        )
    },
    {
        Header: styledHeader('Diunggah Oleh'),
        accessor: 'created_by'
    },
    {
        Header: styledHeader('Tanggal'),
        accessor: 'created_at',
        Cell: ({ value }) => format(new Date(value), 'dd/MM/yyyy')
    },
    {
        Header: styledHeader('Pin'),
        accessor: 'pinned_action',
        Cell: ({ value }) => <PinnedCheckBox onClick={value.onClick} isChecked={value.pinned} />
    },
    {
        Header: styledHeader(' '),
        accessor: 'action',
        Cell: ({ value }) => {
            return <ActionListTableItem onEdit={value.edit} onDelete={value.delete} manageHomeVideo={value.manageHomeVideo} />
        }
    },
]

export const photosColumns = [
    {
        Header: styledHeader('Gambar'),
        accessor: 'image',
        Cell: ({ value }) => <Image h='100px' w='60px' fit='cover' src={value} alt='banner' />

    },
    {
        Header: styledHeader('Judul'),
        accessor: 'title'
    },
    {
        Header: styledHeader('Diunggah oleh'),
        accessor: 'role'
    },
    {
        Header: styledHeader('Tanggal'),
        accessor: 'date',
        Cell: ({ value }) => format(new Date(value), 'dd/MM/yyyy')
    },
    {
        Header: styledHeader('Pin'),
        accessor: 'pin',
        Cell: ({ value }) => <Checkbox isChecked={true} />
    }
]

export const organisasiColumns = [
    {
        Header: styledHeader('Nama'),
        accessor: 'name',
        Cell: (props) => {
            let value = props.value
            let row = props.row.original
            return (
                <Flex alignItems={"center"}>
                    <Avatar mr={5} name={value} src={row.avatar} />
                    <Box>
                        <Text fontWeight={500} textAlign='left' >{value}</Text>
                    </Box>
                </Flex>
            )
        }

    },
    {
        Header: styledHeader('Jabatan'),
        accessor: 'position',
        Cell: ({ value }) => <Text mr={100}>{value}</Text>
    },
    // {
    //     Header: styledHeader('Pin'),
    //     accessor: 'visibility',
    //     Cell: ({ value }) => (
    //         <Box w='100%'>
    //             <Checkbox mr={100} isChecked={value === 'public'} />
    //         </Box>
    //     )
    // },
    {
        Header: styledHeader(" "),
        accessor: 'action',
        Cell: ({ value, row }) => {
            const navigate = useNavigate()
            return <ActionListTableItem onEdit={() => navigate(`/beranda/ire/organisasi/${row.original.id}/edit`)} onDelete={value.delete} />
        }

    }
]
export const testimoniColumns = [
    {
        Header: styledHeader('Nama'),
        accessor: 'name',
        Cell: ({ value }) => {
            let avatar = value.avatar
            if (avatar === null) {
                avatar = noImage;
            }

            return (
                <Flex >
                    <Avatar mr={4} name={value.name} src={avatar} />

                    <Box >
                        <Text fontWeight={500} textAlign='left' >{value.name}</Text>
                        <Text textAlign='left' color='gray.500' >{value.email}</Text>
                    </Box>
                </Flex>
            )
        }

    },
    {
        Header: styledHeader('Pekerjaan'),
        accessor: 'position',
        Cell: ({ value }) => <Text mr={100}>{value}</Text>
    },
    {
        Header: styledHeader('Pin'),
        accessor: 'pin',
        Cell: ({ value }) => (
            <Box w='100%'>
                <Checkbox mr={100} isChecked={true} />
            </Box>
        )
    }
]

// COMMENTS
export const komentarColumns = [
    {
        Header: styledHeader('Penulis'),
        accessor: 'author',
        Cell: ({ value }) => (
            <Tooltip label={value}>
                {textCutter(value, 20)}
            </Tooltip>
        )
    },
    {
        Header: styledHeader('Komentar'),
        accessor: 'content',
        Cell: ({ value }) => (
            <Tooltip label={value}>
                {textCutter(value, 60)}
            </Tooltip>
        )
    },
    {
        Header: styledHeader('Tanggal'),
        accessor: 'created_at',
        Cell: ({ value }) => format(new Date(value), 'dd/MM/yyyy')
    },
    {
        Header: styledHeader('status'),
        accessor: 'approved',
        Cell: ({ value }) => <StatusBadge status={value ? 'success' : ''} message={value ? 'Approved' : 'Unapproved'} />
    },
    {
        Header: styledHeader(" "),
        accessor: 'action',
        Cell: ({ value }) => {
            return <ActionListTableItem id={value} onEdit={null} approveFunction={value} />
        }

    },
]

export const categoriesColumns = [
    {
        Header: styledHeader('Nama'),
        accessor: 'name',

    },
    {
        Header: styledHeader('Deskripsi'),
        accessor: 'desc',
    },
    {
        Header: styledHeader('Jumlah'),
        accessor: 'count',
    },
]
export const tagsColumns = [
    {
        Header: styledHeader('Nama'),
        accessor: 'name',

    },
    {
        Header: styledHeader('Deskripsi'),
        accessor: 'desc',
    },
    {
        Header: styledHeader('Jumlah'),
        accessor: 'count',
    },
]

// DOWNLOAD
export const downloadColumns = [
    {
        Header: styledHeader('Nama'),
        accessor: 'name'
    },
    {
        Header: styledHeader('Email'),
        accessor: 'email'
    },
    {
        Header: styledHeader('Pekerjaan'),
        accessor: 'work'
    },
    {
        Header: styledHeader('Tanggal Download'),
        accessor: 'created_at',
        Cell: ({ value }) => format(new Date(value || '25-1-2020'), 'dd/MM/yyyy')
        // Cell: ({ value }) => format(new Date(value || '25-1-2020'), 'dd/MM/yyyy')
    },

]

// PAGES
export const halamanColumns = [
    {
        Header: styledHeader('Judul'),
        accessor: 'title',

    },
    {
        Header: styledHeader('Tipe'),
        accessor: 'type',
        Cell: ({ value }) => capFirstLetter(value)
    },
    // {
    //     Header: styledHeader('Penulis'),
    //     accessor: 'author_name',
    // },
    {
        Header: styledHeader('Tanggal Publish'),
        accessor: 'created_at',
        Cell: ({ value }) => format(new Date(value), 'dd/MM/yyyy')
    },
    {
        Header: styledHeader(" "),
        accessor: 'action',
        Cell: ({ value }) => {
            const navigate = useNavigate()
            return <ActionListTableItem onEdit={() => navigate(`/halaman/${value.id}/edit`)} onDelete={value.delete} onShow={() => navigate(`/halaman/${value.slug}/${value.type}`)} />
        }

    },
]

// BOOKS
export const booksColumns = [
    {
        Header: styledHeader('Judul'),
        accessor: 'title',
        Cell: ({ value }) => (
            <Flex alignItems='center'>
                <Image mr={4} w='100px' h='100px' alt='Books' fit='cover' src={value.image} />
                <Text>{value.name}</Text>
            </Flex>
        )

    },
    {
        Header: styledHeader('Diunggah oleh'),
        accessor: 'uploader',
    },
    {
        Header: styledHeader('Tanggal'),
        accessor: 'date',
        Cell: ({ value }) => format(new Date(value), 'dd/MM/yyyy')
    },
]

// MEDIA
export const fotoColumns = [
    {
        Header: styledHeader('Gambar'),
        accessor: 'title',
        Cell: ({ value }) => (
            <Flex alignItems='center'>
                <Image mr={4} w='100px' h='100px' alt='Foto' fit='cover' onError={e => e.target.src = noImage} src={value.image} />
                <Tooltip label={value.title}>
                    {textCutter(value.title)}
                </Tooltip>

            </Flex>
        )

    },
    {
        Header: styledHeader('Diupload Oleh'),
        accessor: 'uploader',
    },
    {
        Header: styledHeader('Tanggal'),
        accessor: 'created_at',
        Cell: ({ value }) => format(new Date(value), 'dd/MM/yyyy')
    },
    {
        Header: styledHeader('Pin'),
        accessor: 'pinned_action',
        Cell: ({ value }) => <PinnedCheckBox onClick={value.onClick} isChecked={value.pinned} />
    },
    {
        Header: styledHeader(" "),
        accessor: 'action',
        Cell: ({ value }) => {
            return <ActionListTableItem onEdit={value.edit} onDelete={value.delete} />
        }

    },
]

export const ytVideosColumns = [
    {
        Header: styledHeader('Title'),
        accessor: 'title',
        Cell: ({ value }) => {

            const disclosure = useDisclosure()
            const ref = useRef(null)
            const videoId = getVideoID(value.video)

            return (
                <Flex alignItems='center'>
                    <CustomModal disclosure={disclosure} ref={ref} size='4xl' body={<YoutubeVideoPlayer videoId={videoId} />} />
                    <Button rightIcon={<Icon size='sm' as={FaPlay} />} size='sm' colorScheme='teal' onClick={disclosure.onOpen}>Preview</Button>
                </Flex>
            )
        }
    },
    {
        Header: styledHeader('Uploaded By'),
        accessor: 'uploader',
    },
    {
        Header: styledHeader('Upload Date'),
        accessor: 'date',
        Cell: ({ value }) => format(new Date(value), 'dd/MM/yyyy')
    },
]

export const formDownloadColumns = [
    {
        Header: styledHeader('Nama'),
        accessor: 'name',
        Cell: ({ value }) => (
            <Tooltip label={value}>
                {textCutter(value, 20)}
            </Tooltip>
        )
    },
    {
        Header: styledHeader('Email'),
        accessor: 'email',
        Cell: ({ value }) => (
            <Tooltip label={value}>
                {textCutter(value, 20)}
            </Tooltip>
        )
    },
    {
        Header: styledHeader('Pekerjaan'),
        accessor: 'work',
        Cell: ({ value }) => (
            <Tooltip label={value}>
                {textCutter(value, 20)}
            </Tooltip>
        )
    },
    {
        Header: styledHeader('Produk'),
        accessor: 'product',
        Cell: ({ value }) => (
            <Tooltip label={value}>
                {textCutter(value, 15)}
            </Tooltip>
        )
    },
    // {
    //     Header: styledHeader('Link Publikasi'),
    //     accessor: 'post_id',
    //     Cell: ({value}) =>{
    //         return (<Link to="/"></Link>)
    //     }
    // },
    {
        Header: styledHeader('Alasan Mendownload'),
        accessor: 'action',
        Cell: ({ value }) => {
            return (<Fragment>
                <Button size='sm' colorScheme='teal' onClick={value.onOpenReason}>Lihat</Button>
            </Fragment>)
            // return '...'
        }
    },
]


export const keunggulanColumns = [
    {
        Header: styledHeader('Judul'),
        accessor: 'title',
        Cell: ({ value }) => (
            <Tooltip label={value}>
                {textCutter(value, 30)}
            </Tooltip>
        )
    },
    {
        Header: styledHeader('Deskripsi'),
        accessor: 'description',
        Cell: ({ value }) => (
            <Tooltip label={value}>
                {textCutter(value, 100)}
            </Tooltip>
        )
    },
    {
        Header: styledHeader(" "),
        accessor: 'action',
        Cell: ({ value }) => {
            return <ActionListTableItem onEdit={value.edit} />
        }
    }
]

export const postColumns = [
    {
        Header: styledHeader('Judul'),
        accessor: 'title',
        Cell: ({ value }) => (
            <Tooltip label={value}>
                {textCutter(value, 40)}
            </Tooltip>
        )
    },
    {
        Header: styledHeader('Penulis'),
        accessor: 'author_name',
    },
    {
        Header: styledHeader('Tanggal Publish'),
        accessor: 'published_at',
        Cell: ({ value }) => format(new Date(value), 'dd/MM/yyyy')
    },
    {
        Header: styledHeader('Status'),
        accessor: 'visibility',
        Cell: ({ value }) => {
            return <StatusBadge status={value === "public" ? 'success' : ''} message={value} />
        }
    },
    {
        Header: styledHeader('Pin'),
        accessor: 'pinned_action',
        Cell: ({ value }) => <PinnedCheckBox onClick={value.onClick} isChecked={value.pinned} />
    },
    {
        Header: styledHeader(" "),
        accessor: 'action',
        Cell: ({ value }) => {
            return <ActionListTableItem onEdit={value.onEdit} onDelete={value.delete} />
        }

    }
]

export const katalogColumns = [
    {
        Header: styledHeader('Gambar'),
        accessor: 'title',
        Cell: ({ value }) => (
            <Flex alignItems='center'>
                <Image mr={4} w='100px' h='100px' alt={value.title} fit='cover' onError={e => e.target.src = noImage} src={value.url} />
                <Tooltip label={value.title}>
                    {textCutter(value.title)}
                </Tooltip>

            </Flex>
        )

    },
    {
        Header: styledHeader('Diupload Oleh'),
        accessor: 'author_name',
    },
    {
        Header: styledHeader(" "),
        accessor: 'action',
        Cell: ({ value }) => {
            return <ActionListTableItem onEdit={value.edit} />
        }

    },
]