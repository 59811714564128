import { createBrowserRouter } from 'react-router-dom';
import AuthRoot from '../pages/Auth/AuthRoot';
import Komentar from '../pages/Dashboard/Komentar/Komentar';
import Dashboard from '../pages/Dashboard/Dashboard/Dashboard';
import DashboardRoot from '../pages/Dashboard/DashboardRoot';
import Banner from '../pages/Dashboard/Beranda/Banner/Banner';
import PageNotFound from '../pages/PageNotFound';
import TentangEdit from '../pages/Dashboard/Beranda/Tentang/Tentang/TentangEdit';
import Organisasi from '../pages/Dashboard/Beranda/Tentang/Organisasi/Organisasi';
import OrganisasiAdd from '../pages/Dashboard/Beranda/Tentang/Organisasi/OrganisasiAdd';
import NextGenEdit from '../pages/Dashboard/Beranda/NextGen/NextGenEdit';
import Publikasi from '../pages/Dashboard/Beranda/Publikasi/Publikasi';
import Foto from '../pages/Dashboard/Beranda/GaleriFoto/Foto';
import FotoAdd from '../pages/Dashboard/Beranda/GaleriFoto/FotoAdd';
import Video from '../pages/Dashboard/Beranda/GaleriVideo/Video';
import PublikasiAdd from '../pages/Dashboard/Beranda/Publikasi/PublikasiAdd';
import VideoAdd from '../pages/Dashboard/Beranda/GaleriVideo/VideoAdd';
import TrainingEdit from '../pages/Dashboard/Beranda/Training/TrainingEdit';
import PerpustakaanEdit from '../pages/Dashboard/Beranda/Perpustakaan/PerpustakaanEdit';
import Halaman from '../pages/Dashboard/Halaman/Halaman';
import HalamanAdd from '../pages/Dashboard/Halaman/HalamanAdd';
import CustomPageWrapper from '../pages/CustomPageWrapper';
import PMB from '../pages/Dashboard/Beranda/PMB/Index';
import Artikel from 'src/pages/Dashboard/Beranda/Artikel/Index';
import ArtikelAdd from 'src/pages/Dashboard/Beranda/Artikel/ArtikelAdd';
import Distributor from 'src/pages/Dashboard/Beranda/Distributor/Index';
import DistributorAdd from 'src/pages/Dashboard/Beranda/Distributor/DistributorAdd';
import PostAdd from 'src/pages/Dashboard/Beranda/Post/PostAdd';
import Post from 'src/pages/Dashboard/Beranda/Post/Post';
import Jurusan from 'src/pages/Dashboard/Beranda/Jurusan/Index';
import JurusanAdd from 'src/pages/Dashboard/Beranda/Jurusan/JurusanAdd';

const generatePostEditorRoute = (ComponentIndex, ComponentAdd, pageName) => {
    return [{
        path: `beranda/${pageName}`,
        element: <ComponentIndex />
    },
    {
        path: `beranda/${pageName}/add`,
        element: <ComponentAdd />
    },
    {
        path: `beranda/${pageName}/:slug/add`,
        element: <ComponentAdd />
    },
    {
        path: `beranda/${pageName}/:slug/edit`,
        element: <ComponentAdd onEdit={true} />
    }]
}

export const routes = createBrowserRouter([
    {
        path: '/',
        element: <DashboardRoot />,
        errorElement: <PageNotFound />,
        children: [
            // DASHBOARD
            {
                path: 'dashboard',
                element: <Dashboard />
            },

            // BERANDA
            {
                path: 'beranda/banner',
                element: <Banner />
            },
            {
                path: 'beranda/pmb',
                element: <PMB />
            },
            {
                path: 'beranda/jurusan',
                element: <Jurusan />
            },
            {
                path: 'beranda/jurusan/:slug',
                element: <JurusanAdd />
            },
            ...generatePostEditorRoute(Artikel, ArtikelAdd, 'artikel'),
            ...generatePostEditorRoute(Distributor, DistributorAdd, 'distributor'),
            {
                path: 'beranda/tentang-kami',
                element: <TentangEdit />
            },
            {
                path: 'beranda/tentang-kami',
                element: <TentangEdit />
            },
            {
                path: 'beranda/organisasi',
                element: <Organisasi />
            },
            {
                path: 'beranda/organisasi/add',
                element: <OrganisasiAdd />
            },
            {
                path: 'beranda/organisasi/:id/edit',
                element: <OrganisasiAdd isEdit={true} />
            },
            {
                path: 'beranda/next-gen/edit',
                element: <NextGenEdit />
            },
            {
                path: 'beranda/publikasi',
                element: <Publikasi />
            },
            {
                path: 'beranda/publikasi/add',
                element: <PublikasiAdd />
            },
            {
                path: 'beranda/publikasi/:slug/add',
                element: <PublikasiAdd />
            },
            {
                path: 'beranda/publikasi/:slug/edit',
                element: <PublikasiAdd onEdit={true} />
            },

            {
                path: 'beranda/foto',
                element: <Foto />
            },
            {
                path: 'beranda/foto/add',
                element: <FotoAdd />
            },
            {
                path: 'beranda/foto/:id/edit',
                element: <FotoAdd onEdit={true} />
            },
            {
                path: 'beranda/video',
                element: <Video />
            },
            {
                path: 'beranda/video/add',
                element: <VideoAdd />
            },
            {
                path: 'beranda/video/:id/edit',
                element: <VideoAdd onEdit={true} />
            },


            // HALAMAN
            {
                path: 'halaman',
                element: <Halaman />
            },
            {
                path: 'halaman/add',
                element: <HalamanAdd />
            },
            {
                path: 'halaman/:id/edit',
                element: <HalamanAdd onEdit={true} />
            },

            {
                path: 'halaman/:slugPage/post',
                element: <CustomPageWrapper><Post customPage={true} /></CustomPageWrapper>
            },
            {
                path: 'halaman/:slugPage/post/add',
                element: <CustomPageWrapper><PostAdd customPage={true} /></CustomPageWrapper>

            },
            {
                path: 'halaman/:slugPage/post/:slug/add',
                element: <CustomPageWrapper><PostAdd customPage={true} /></CustomPageWrapper>
            },
            {
                path: 'halaman/:slugPage/post/:slug/edit',
                element: <CustomPageWrapper><PostAdd customPage={true} onEdit={true} /></CustomPageWrapper>
            },
            {
                path: 'halaman/:slugPage/publication',
                element: <CustomPageWrapper><Publikasi customPage={true} /></CustomPageWrapper>
            },
            {
                path: 'halaman/:slugPage/publication/add',
                element: <CustomPageWrapper><PublikasiAdd customPage={true} /></CustomPageWrapper>
            },
            {
                path: 'halaman/:slugPage/publication/:slug/add',
                element: <CustomPageWrapper><PublikasiAdd customPage={true} /></CustomPageWrapper>
            },
            {
                path: 'halaman/:slugPage/publication/:slug/edit',
                element: <CustomPageWrapper><PublikasiAdd customPage={true} onEdit={true} /></CustomPageWrapper>
            },
            {
                path: 'halaman/:slugPage/gallery',
                element: <CustomPageWrapper><Foto customPage={true} /></CustomPageWrapper>
            },
            {
                path: 'halaman/:slugPage/gallery/add',
                element: <CustomPageWrapper><FotoAdd customPage={true} /></CustomPageWrapper>
            },
            {
                path: 'halaman/:slugPage/gallery/:id/edit',
                element: <CustomPageWrapper><FotoAdd onEdit={true} customPage={true} /></CustomPageWrapper>
            },
            {
                path: 'halaman/:slugPage/video',
                element: <CustomPageWrapper><Video customPage={true} /></CustomPageWrapper>
            },
            {
                path: 'halaman/:slugPage/video/add',
                element: <CustomPageWrapper><VideoAdd customPage={true} /></CustomPageWrapper>
            },
            {
                path: 'halaman/:slugPage/video/:id/edit',
                element: <CustomPageWrapper><VideoAdd onEdit={true} customPage={true} /></CustomPageWrapper>
            },

            // COMMENTS
            {
                path: 'komentar',
                element: <Komentar />,
            },
            // {
            //     path: 'download',
            //     element: <Download />,
            // },
        ]
    },
    {
        path: '/auth',
        element: <AuthRoot />,
        errorElement: <PageNotFound />,
        children: [
            {
                path: 'logout',
                element: <></>
            }
        ]
    },


])