export const getLocalSession = () => {
    const data = JSON.parse(localStorage.getItem('session'))

    return data
}

export const saveLocalSession = (data) => {
    localStorage.setItem('session', JSON.stringify(data))
}

export const removeLocalSession = () => {
    localStorage.removeItem('session')
}