import { Box, Button, Flex, FormControl, FormLabel, Icon, Input, Link as CakraLink, Text, Tooltip, useToast } from '@chakra-ui/react'

import React, { Fragment, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { signIn } from '../../services/auth.service'
import { saveLocalSession } from '../../services/session.service'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import { useState } from 'react'
import { useRef } from 'react'
import { AuthContextState } from '../../context/AuthContext'


function Form() {
  const navigate = useNavigate()
  const toast = useToast()
  const [onEmail, setOnEmail] = React.useState(true)
  const [email, setEmail] = React.useState('')
  const [pass, setPass] = React.useState('')
  const [emailError, setEmailError] = React.useState('')
  const [passError, setPassError] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [showPass, setShowPass] = useState(false)
  const [auth, setAuth] = useContext(AuthContextState)
  let loginRef = useRef()

  const onNextHandler = () => {
    if (email === '') {
      // email kosong
      setEmailError('Email wajib diisi')

      return
    }
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      // email tidak valid

      setEmailError('Format email tidak sesuai')
      return
    }

    setEmailError('')
    setOnEmail(false) //move to password

  }

  const onSubmitHandler = () => {
    if (pass === '') {
      // password kosong
      setPassError('Password wajib diisi')
      return
    }
    setPassError('')

    // Login Action
    setLoading(true)
    signIn(email, pass)
      .then(({ data }) => {
        setAuth(data.data)
        saveLocalSession(data.data)
        // setLoading(false)
        toast({
          title: "Login Berhasil",
          description: "Login Berhasil",
          status: 'success',
          position: 'top'
        })
        setTimeout(() => navigate('/dashboard'), 1000)

      })
      .catch(error => {
        toast({
          title: 'Login gagal',
          description: error.response ? error.response.data.message : 'Login Failed',
          status: 'error',
          position: 'top'
        })
        setOnEmail(true)
        setPass('')
        setLoading(false)
      })


  }

  return (
    <Fragment>

      <FormControl>
        <FormLabel fontStyle={500} > {onEmail ? 'Email' : 'Password'} </FormLabel>

        <Input isInvalid={emailError} onKeyDown={(e) => e.key === 'Enter' ? onNextHandler() : ''} style={{ display: !onEmail ? 'none' : 'inherit' }} value={email} autoFocus onChange={(e) => setEmail(e.target.value)} name='email' bg='white' type='text' placeholder='example@gmail.com' />
        <Flex alignItems='center' style={{ display: onEmail ? 'none' : 'flex' }}>
          <Input isInvalid={passError} onKeyDown={(e) => e.key === 'Enter' ? onSubmitHandler() : ''} isDisabled={loading} value={pass} onChange={(e) => setPass(e.target.value)} name='password' bg='white' type={showPass ? 'text' : 'password'} placeholder='Masukan password' />
          <Icon as={showPass ? AiFillEye : AiFillEyeInvisible} style={{ cursor: 'pointer' }} _hover={{ color: 'gray.500' }} onClick={() => setShowPass(!showPass)} boxSize={6} ml={2} color='gray.400' />
        </Flex>

        <Text textAlign='left' fontSize='14px' color='red.500' lineHeight={8}>{emailError || passError}</Text>
      </FormControl>
      {
        !onEmail ?
          <Box textAlign='left' mt={4}>
            <CakraLink as={Link} to='#' fontWeight={600} fontSize='12px' color='#537645'>Lupa Password?</CakraLink>
          </Box> : ''

      }
      <Button isLoading={loading} spinnerPlacement='start' loadingText='Login' colorScheme='teal' variant='solid' onClick={onEmail ? onNextHandler : onSubmitHandler} width='100%' bg='btn-login' mt={8} >{onEmail ? 'Selanjutnya' : 'Login'}</Button>
    </Fragment>
  )
}

export default Form