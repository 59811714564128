import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'

export function useUrlQuery() {
    const [searchParam, setSearchParam] = useSearchParams()
    const [query, setQuery] = useState({})

    const getQuery = (key) => {
        return query[key]
    }

    const addQuery = (key, value) => {
        setSearchParam({
            ...query,
            [key]: value
        })
    }

    const removeQuery = (key) => {
        const temp = { ...query }
        delete temp[key]

        setSearchParam({
            ...temp
        })
    }

    useEffect(() => {
        const temp = {}
        searchParam.forEach((item, key) => {
            temp[key] = item
        })
        setQuery(temp)
    }, [searchParam])

    // console.log(query, 'query')

    return { urlQuery: searchParam, addQuery, removeQuery, getQuery }
}

