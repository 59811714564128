import { Accordion, Box, Flex, Image, Spacer, Stack } from '@chakra-ui/react'
import React from 'react'
import logo from '../../../../assets/images/logo.png'
import SidebarItem from './SidebarItem'
import { LIST_SIDEBAR } from '../../../../lib/const/sidebar-list'

function Sidebar() {
    return (
        <Box width='100%' >
            <Box pt={4} pl={8} width='94px' height='80px'>
                <Image src={logo} alt='PVC logo' />
            </Box>
            <Stack>
                <Accordion allowToggle >
                    {
                        LIST_SIDEBAR.map((item, index) => (

                            <SidebarItem key={index} {...item} />
                        ))
                    }
                </Accordion >
            </Stack>
        </Box>
    )
}

export default Sidebar