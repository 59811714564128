import { Box, Flex, HStack, Input, InputGroup, InputRightElement, Select, Spacer, Stack, Table, TableCaption, TableContainer, Tbody, Td, Text, Thead, Tr } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { Search2Icon } from '@chakra-ui/icons'
import StatsBox from '../_components/StatsBox'
import TableData from '../_components/TableData'
import { dashboardColumns, getDashboardColumns, ireUpdateColumns, publikasiColumns } from '../../../lib/const/table-columns'
import { useOutletContext } from 'react-router-dom'
import { getAllIreUpdate, getAllPost, getAllPublikasi, getCount, getFormDownload, getFormDownloads, getIreUpdate, getLoginStatus, getPublication, getPublications, getPublikasi } from '../../../services/user.services'
import { useContext } from 'react'
import PageTitle from '../_components/PageTitle'
import { AuthContextState } from '../../../context/AuthContext'

function Dashboard() {
    const [, setTopRightButton] = useOutletContext()
    const [auth,] = useContext(AuthContextState)
    const [countProduct, setCountProduct] = React.useState(0)
    const [countDistributor, setCountDistributor] = React.useState(0)
    const [countCatalog, setCountCatalog] = React.useState(0)
    const [countHalamanCustom, setCountHalamanCustom] = React.useState(0)
    const [product, setProduct] = React.useState([])
    const [distributor, setDistributor] = React.useState([])
    // const [publikasiDownload, setPublikasiDownload] = React.useState([])
    const [isLoadingCount, setLoadingCount] = React.useState(true)
    const [isLoadingProduct, setLoadingProduct] = React.useState(true)
    const [isLoadingDistributor, setLoadingDistributor] = React.useState(true)
    // const [isLoadingDownload, setLoadingDownload] = React.useState(true)

    const setAllLoading = (value) => {
        setLoadingCount(value)
        setLoadingProduct(value)
        setLoadingDistributor(value)
        // setLoadingDownload(value)
    }

    const init = () => {

        // GET COUNT
        getCount(auth)
            .then(response => {
                setCountProduct(response.data.data.product)
                setCountCatalog(response.data.data.catalog)
                setCountDistributor(response.data.data.distributor)
                setCountHalamanCustom(response.data.data.custom_page)
                // console.log('1, COUNT')
                setLoadingCount(false)
            })
            .catch(e => {
                console.log(e)
                setLoadingCount(false)
            })

        // GET Produk
        getAllPost(auth, 'produk',{})
            .then(response => {
                const data = response.data.data.map(item => {
                    item.author_name = item.author_name ? item.author_name : 'Admin'
                    return item
                }).slice(0, 3)

                setProduct(data)
                // console.log('Product', response.data.data)
                // console.log('2, Product')
                // setLoading(false)
                setLoadingProduct(false)
            })
            .catch(e => {
                setLoadingProduct(false)
                console.log(e)
            })

        // GET Distributor
        getAllPost(auth, 'distributor',{})
            .then(response => {
                const data = response.data.data.map(item => {
                    item.author_name = item.author_name ? item.author_name : 'Admin'
                    return item
                }).slice(0, 3)
                setDistributor(data)
                // console.log('Publikasi', response.data.data)
                // setLoading(false)
                setLoadingDistributor(false)
                // console.log('3, PUBLIKASI')

            })
            .catch(e => {
                setLoadingDistributor(false)
                console.log(e)
            })

        // GET RIWAYAT DOWNLOAD
        // getFormDownloads(auth)
        //     .then(response => {
        //         setPublikasiDownload(response.data.data.slice(0, 3))
        //         // console.log('FormDownload', response.data.data)
        //         // setLoading(false)

        //         setLoadingDownload(false)
        //         // console.log('4, RIWAYAT DOWNLOAD')
        //     })
        //     .catch(e => {
        //         setLoadingDownload(false)
        //         console.log(e)
        //     })
    }

    useEffect(() => {
        setTopRightButton(null)
        init();
    }, [])

    const tableProductProps = {
        columns: getDashboardColumns('produk'),
        data: product,
        isLoading: isLoadingProduct,
        showMore: {
            name: 'Menuju Produk', url: '/beranda/produk'
        }
    }

    const tableDistributorProps = {
        columns: getDashboardColumns('distributor'),
        data: distributor,
        isLoading: isLoadingDistributor,
        showMore: {
            name: 'Menuju Distributor', url: '/beranda/distributor'
        }
    }

    // const tablePublikasiDownloadProps = {
    //     columns: getDashboardColumns('download'),
    //     data: publikasiDownload,
    //     isLoading: isLoadingDownload,
    //     showMore: {
    //         name: 'Menuju Riwayat Unduh', url: '/download'
    //     }
    // }

    return (
        <div>
            <Flex>
                <StatsBox isLoading={isLoadingCount} title={'Produk'} value={countProduct} bg={'green.500'} />
                <Spacer />
                <StatsBox isLoading={isLoadingCount} title={'Katalog'} value={countDistributor} bg={'red.500'} />
                <Spacer />
                <StatsBox isLoading={isLoadingCount} title={'Distributor'} value={countDistributor} bg={'blue.500'} />
                <Spacer />
                <StatsBox isLoading={isLoadingCount} title={'Halaman Custom'} value={countHalamanCustom} bg={'teal.500'} />
            </Flex>

            <RenderTable tableProps={tableProductProps} title='Produk' />
            <RenderTable tableProps={tableDistributorProps} title='Distributor' />

        </div>
    )

}

export default Dashboard

function RenderTable({ tableProps, title }) {
    return (
        <Box bg='gray.50' p={4} mt={8} color='gray.700'>
            <PageTitle title={title} />
            {/* <Text as='b' fontSize='md'>{title}</Text> */}
            <TableData {...tableProps} />
        </Box>

    )
}