import { ChakraProvider } from '@chakra-ui/react';
import { createContext, useContext, useEffect, useReducer, useState } from 'react';
import { Helmet } from 'react-helmet';
import { RouterProvider } from 'react-router-dom';
import { routes } from './routes/routes';
import theme from './theme/theme';
import logo from './assets/images/logo.png'
import { corporate_name } from './lib/const/string';
import AuthContext from './context/AuthContext';



function App() {
  return (
    <AuthContext>
      <ChakraProvider theme={theme}>
        <Helmet>
          <title>{corporate_name}</title>
          <link rel="icon" href={logo} />
        </Helmet>
        <RouterProvider router={routes} />
      </ChakraProvider>
    </AuthContext>

  )
}

export default App;
