import { Box, Button, Flex, Menu, MenuButton, MenuGroup, MenuItem, MenuList, Spacer, Text } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { FaUser } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { AuthContextState } from '../../../context/AuthContext'

function Navbar({ drawerButton }) {
    const [auth, setAuth] = useContext(AuthContextState)
    const user = auth === null ? null : auth.user
    return (
        <Menu w='100%'>
            <Flex pl={4} h='86px' alignItems='center'>
                {drawerButton}
                <Spacer />
                <MenuButton as={Button} h='100%' variant='ghost' color='white' _hover={{ bg: '#2D3748' }} _active={{ bg: '#2D3748' }} rightIcon={<FaUser />}>
                    {`${user !== null ? user.first_name : ''} ${user !== null ? user.last_name : ''}`}
                </MenuButton>
                <MenuList>
                    <MenuGroup title='Profile'>
                        <MenuItem as={Link} to='/auth/logout'>
                            Log Out
                        </MenuItem>
                    </MenuGroup>
                </MenuList>
            </Flex>
        </Menu>
    )
}

export default Navbar