import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from '@chakra-ui/react'
import React from 'react'

function CustomModal({ disclosure, ref, closeOnOverlayClick, title, body, footer, size = 'xl', loading = false, closeButton = true }) {
    const { isOpen, onOpen, onClose } = disclosure
    const finalRef = ref


    return (
        <Modal closeOnEsc={false} closeOnOverlayClick={closeOnOverlayClick} finalFocusRef={finalRef} size={size} isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{title}</ModalHeader>
                {loading ?
                    ''
                    :
                    closeButton ? <ModalCloseButton /> : ''
                }
                <ModalBody width='100%' maxH={"60vh"} overflowY="auto">
                    {body}
                </ModalBody>
                <ModalFooter>
                    {footer}
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default CustomModal