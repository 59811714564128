import { AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box } from '@chakra-ui/react'
import React from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import SidebarChild from './SidebarChild'

function SidebarItem({ name, link, child }) {
    const { pathname } = useLocation()
    const isActive = () => pathname.split('/')[1] === link.split('/')[1]

    const hasChild = () => {
        return child.length
    }
    const accordionButton = () =>
    (
        <AccordionButton>
            <Box flex='1' textAlign='left' fontWeight={isActive() ? 700 : 400} color={isActive() ? 'sidebar-active' : 'gray.500'}>
                {name.toUpperCase()}
            </Box>
            {hasChild() ? <AccordionIcon color='gray.500' /> : ''}
        </AccordionButton >
    )
    return (
        <AccordionItem px={4} my={2} style={{ border: 'none' }} >
            {
                !hasChild() ?
                    <Link to={link}>
                        {accordionButton()}
                    </Link> :
                    accordionButton()
            }
            {
                hasChild() ?
                    <AccordionPanel>
                        {
                            child.map((item, index) => (<SidebarChild key={index} {...item} ></SidebarChild>))
                        }
                    </AccordionPanel>
                    :
                    ''
            }
        </AccordionItem>
    )
}

export default SidebarItem