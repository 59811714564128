import { Button, Flex, Image, } from '@chakra-ui/react'
import React from 'react'
import { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import Navbar from './Auth/components/Navbar'
import errImage from '../assets/images/404.jpg'
import { ArrowBackIcon } from '@chakra-ui/icons'

function PageNotFound() {
    const navigate = useNavigate()
    return (
        <Fragment>
            <Navbar />
            <Flex justifyContent='center' alignItems='center' direction='column'>
                <Image src={errImage} my={16} w='50%' alt='Not Found' />
                {/* <a href="https://www.freepik.com/free-vector/404-error-with-landscape-concept-illustration_20602785.htm#query=404&position=19&from_view=keyword">Image by storyset</a> on Freepik */}
                <Button leftIcon={<ArrowBackIcon />} colorScheme='teal' onClick={() => navigate('/')}  > Back to Dashboard </Button>
            </Flex>
        </Fragment >

    )
}

export default PageNotFound