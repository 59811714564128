import { Box, Button, Flex, Icon, Input, Select, Spacer, Text, useToast } from '@chakra-ui/react'
import React from 'react'
import { Fragment } from 'react'
import { useContext } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { createdToast, updatedToast } from '../../../lib/const/toast'
import { getDateNow } from '../../../lib/helper/getDateNow'
import { addPages, getCategories, getPage, UpdatePages } from '../../../services/user.services'
import { AiOutlineCheckCircle } from 'react-icons/ai'
import PageTitle from '../_components/PageTitle'
import { AuthContextState } from '../../../context/AuthContext'

// post
// gallery
// video
// publication

function HalamanAdd({ onEdit = false }) {
    const [auth,] = useContext(AuthContextState)
    const [, setTopRightButton] = useOutletContext()
    const navigate = useNavigate()
    const param = useParams()
    const toast = useToast()
    const [title, setTitle] = useState('')
    const [titleError, setTitleError] = useState('')
    const [loading, setLoading] = useState(true)
    const [tipe, setTipe] = useState('post')
    const [datePublish, setDatePublish] = useState(getDateNow())
    const [visibility, setVisibility] = useState('public')
    const [kategori, setKategori] = useState('')

    const [kategoriA, setKategoriA] = useState([])
    const [kategoriB, setKategoriB] = useState([])
    const [kategoriC, setKategoriC] = useState([])

    const [kategoriASelected, setKategoriASelected] = useState('')
    const [kategoriBSelected, setKategoriBSelected] = useState('')
    const [kategoriCSelected, setKategoriCSelected] = useState('')

    // HANDLE PUBLISH

    const onPublishHandler = async () => {
        setLoading(true)
        setTitleError('')

        if (title.trim() === '') {
            setTitleError('Judul harus diisi')
            return
        }

        try {
            const payload = {
                title: title,
                type: tipe,
                sub_page_id: kategori,
                visibility: visibility,
                publised_at: datePublish,
                lang: 'id'
            }
            if (!onEdit) {
                const response = addPages(auth, payload)
                toast(createdToast)
            } else {
                const response = UpdatePages(auth, { ...payload, id: param.id })
                toast(updatedToast)

            }
            navigate('/halaman')
            // console.log(response.data.data)

        } catch (e) {
            console.log(e)
        }

        setLoading(false)
    }

    // HANDLE KATEGORI
    const onKategoriAChanged = async (e) => {
        setLoading(true)
        const value = e.target.value
        setKategoriASelected(value)
        setKategoriB([])
        setKategoriBSelected('')
        setKategoriC([])
        setKategoriCSelected('')

        if (value) {
            try {
                const response = await getCategories(auth, value)
                // console.log(response.data.data)
                setKategoriB(response.data.data)
            } catch (e) {
                console.log(e)
            }
        }

        setKategori(value)
        setLoading(false)

    }

    const onKategoriBChanged = async (e) => {
        setLoading(true)
        const value = e.target.value
        setKategoriBSelected(value)
        setKategoriC([])
        setKategoriCSelected('')

        if (value) {
            setKategori(value)
            try {
                const response = await getCategories(auth, value)
                // console.log(response.data.data)
                setKategoriC(response.data.data)
            } catch (e) {
                console.log(e)
            }
        } else {
            setKategori(kategoriASelected)
        }
        setLoading(false)
    }

    const onKategoriCChanged = (e) => {
        setLoading(true)
        const value = e.target.value
        setKategoriCSelected(value)

        if (value) {
            setKategori(value)
        } else {
            setKategori(kategoriBSelected)
        }
        setLoading(false)
    }

    const searchKategori = async (query, listA) => {
        // PHASE I

        // for (let a = 0; a < listA.length; a++) {
        //     listAIdA.push(listA[a].id)
        //     if (query === listA[a].id) {
        //         setKategoriASelected(listA[a].id)
        //         return
        //     }
        // }

        // PHASE II
        let response
        for (let a = 0; a < listA.length; a++) {

            if (query === listA[a].id) {
                setKategoriASelected(listA[a].id)
                return
            }

            response = await getCategories(auth, listA[a].id)
            const listB = response.data.data
            for (let b = 0; b < listB.length; b++) {
                if (query === listB[b].id) {
                    setKategoriASelected(listA[a].id)
                    setKategoriB(listB)
                    setKategoriBSelected(listB[b].id)
                    return
                }

                response = await getCategories(auth, listB[b].id)
                const listC = response.data.data
                for (let c = 0; c < listC.length; c++) {
                    if (query === listC[c].id) {
                        setKategoriASelected(listA[a].id)
                        setKategoriB(listB)
                        setKategoriBSelected(listB[b].id)
                        setKategoriC(listC)
                        setKategoriCSelected(listC[c].id)
                        return
                    }
                }

            }
        }

        // PHASE III 



        console.log('TIDAK KETEMU')

        return
    }

    // END HANDLE KATEGORI



    const init = async () => {
        let list = []
        let query = ''

        try {
            const response = await getCategories(auth)
            list = response.data.data
            setKategoriA(response.data.data)
        } catch (e) {
            console.log(e)
        }

        if (onEdit) {

            try {
                const response = await getPage(auth, param.id)
                const page = response.data.data
                // console.log(response.data.data)
                query = page.parent_id
                setTitle(page.title)
                setTipe(page.type)
                setKategori(query)
                setVisibility(page.visibility)
                setDatePublish(page.published_at ? page.published_at.slice(0, 10) : getDateNow())
            } catch (e) {
                console.log(e)
            }

            if (query) {
                await searchKategori(query, list)
            }

            // MULAI MENCARI
            // SELESAI MENCARI
        }


        setLoading(false)
    }

    useEffect(() => {
        setTopRightButton(null)
        init()
    }, [])
    return (
        <Fragment>
            <Box bg='gray.100' p={4} fontWeight={700} roundedTop={4}>
                <PageTitle title='Halaman' showIcon={false} mt={0} mb={0} />
            </Box>
            <Box p={4} bg='gray.50'>
                <Box>
                    <Text fontWeight={500}>Judul Halaman</Text>
                    <Input isInvalid={titleError} isDisabled={loading} placeholder='Judul Halaman' bg='white' my={4} value={title} onChange={(e) => setTitle(e.target.value)} />
                    {titleError ? <Text fontSize={14} color='red.500'>{titleError}</Text> : ''}
                </Box>
                <Box>
                    <Text fontWeight={500}>Tipe</Text>
                    <Select isDisabled={loading || onEdit} value={tipe} onChange={(e) => setTipe(e.target.value)} bg='white' >
                        <option value='post' >Post</option>
                        <option value='gallery' >Galeri Foto</option>
                        <option value='video' >Galeri Video</option>
                        <option value='publication' >Publikasi</option>
                    </Select>
                </Box>

                <Box mt={8} color='gray.700' bg='gray.50' rounded={4}>
                    <Box bg='gray.100' p={4} fontWeight={700} roundedTop={4}>
                        <Text>Kategori</Text>
                    </Box>
                    <Box p={4}>
                        <Box pb={4}>
                            <Text>Kategori</Text>
                            <Flex alignItems='center'>

                                <Select value={kategoriASelected} isDisabled={loading} onChange={onKategoriAChanged} bg='white'>
                                    <option value=''>Tidak Diatur</option>
                                    {
                                        kategoriA.map(({ id, title }) => (
                                            <option value={id} key={id} >{title}</option>
                                        ))
                                    }
                                </Select>
                                <Icon as={AiOutlineCheckCircle} ml={2} color={kategoriASelected ? 'green' : 'gray.50'} />
                            </Flex>
                        </Box>
                        <Box pb={4}>
                            <Text>Kategori Utama</Text>
                            <Flex alignItems='center' >
                                <Select value={kategoriBSelected} isDisabled={loading} onChange={onKategoriBChanged} bg='white'>
                                    <option value=''>Tidak Diatur</option>
                                    {
                                        kategoriB.map(({ id, title }) => (
                                            <option value={id} key={id} >{title}</option>
                                        ))
                                    }
                                </Select>
                                <Icon as={AiOutlineCheckCircle} ml={2} color={kategoriBSelected ? 'green' : 'gray.50'} />
                            </Flex>
                        </Box>
                        <Box pb={4}>
                            <Text>Sub Kategori</Text>
                            <Flex alignItems='center' >
                                <Select value={kategoriCSelected} isDisabled={loading} onChange={onKategoriCChanged} bg='white'>
                                    <option value=''>Tidak Diatur</option>
                                    {
                                        kategoriC.map(({ id, title }) => (
                                            <option value={id} key={id} >{title}</option>
                                        ))
                                    }
                                </Select>
                                <Icon as={AiOutlineCheckCircle} ml={2} color={kategoriCSelected ? 'green' : 'gray.50'} />
                            </Flex>
                        </Box>
                    </Box>
                    <Box bg='gray.100' p={4} fontWeight={700} roundedTop={4}>
                        <Text>Publish</Text>
                    </Box>
                    <Box p={4}>
                        <Box pb={4}>
                            <Text>Visibility</Text>
                            <Select value={visibility} isDisabled={loading} onchange={(e) => setVisibility(e.target.value)} bg='white'>
                                <option value='public' >Publik</option>
                                <option value='private' >Private</option>
                            </Select>
                        </Box>
                        <Box pb={4}>
                            <Text>Publish</Text>
                            {/* <Input type={datePublishType} value={datePublish} onBlur={() => setDatePublishType('text')} onFocus={() => setDatePublishType('date')} onChange={(e) => { setDatePublish(`${format(new Date(e.target.value), 'dd/MM/yyyy')}`); e.target.blur() }} bg='white' placeholder='Tanggal Publish' /> */}
                            <Input type='date' isDisabled={loading} value={datePublish} onChange={(e) => { setDatePublish(e.target.value); console.log(e.target.value) }} bg='white' min={new Date().toISOString().split("T")[0]} placeholder='Tanggal Publish' />
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Flex>
                <Spacer />
                <Button isLoading={loading} mt={4} mb={16} colorScheme='teal' bg='teal.500' onClick={onPublishHandler}>Simpan</Button>
            </Flex>

        </Fragment>
    )
}

export default HalamanAdd